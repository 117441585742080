import React from 'react'
import ProfilePage from '../components/ProfilePage/ProfilePage'


function profile() {
  return (
    <div>
    <ProfilePage />
    </div>
  )
}

export default profile