export function decodeFBString(str) {
    const arr = [];
    if(str && str.length) {
      for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        arr.push(charCode);
      }
      const uintArray = new Uint8Array(arr);
      const decoder = new TextDecoder("utf-8");
      const decodedStr = decoder.decode(uintArray);
      return decodedStr;
    }
  }

export function disableRightClick(event) {
    event.preventDefault();
}

export function compressedImage(url) {
  //only for cloudinary images
  const uploadIndex = url.indexOf("/image/upload/");
  
  if (uploadIndex === -1) {
    return url; // Return the original URL if "/image/upload/" is not found
  }

  const modifiedUrl = url.slice(0, uploadIndex + 14) + "w_1000/q_auto/f_auto/" + url.slice(uploadIndex + 14);
  return modifiedUrl;
}