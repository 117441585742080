import React from 'react'
import Stories from "react-insta-stories"
import profile from "../../data/profiles/reclaimedByNature.json";
import { useParams } from 'react-router-dom';
import allHighlights from "../../data/content/rpn_highlights.json"
import stories from "../../data/content/rpn_stories.json";
import _ from 'lodash';
import { compressedImage } from '../../utils';

function Highlight() {
  const title = useParams().title;
  let story = stories[0];
  let highlight;
  
  if(title && title.length) {
    story = stories.find(
      (story) => {
          return story.title.toString() === title.toString();
      }
    );
    highlight = allHighlights.highlights.find(
      (theOne) => {
        return _.toLower(theOne.title.toString()) === _.toLower(title.toString())
      }
    )
  }

  const highlights = story.content.map((each) => {
    if(each.header && each.header.subheading) {
      if(highlight) {
        return {
          header: {
            heading: highlight.title,
            profileImage: compressedImage(highlight.thumbnail),
            subheading: each.header.subheading || null
          },
          url: each.url,
          type: each.type,
          preloadResource: false,
          key: each.url
        } 
      } else {
        return {
          header: {
            heading:"reclamado.por.la.naturaleza",
            profileImage: compressedImage(profile.profile_user[0].media_map_data["Profile Photo"].uri),
            subheading: each.header.subheading || null
          },
          url: each.url,
          type: each.type,
          preloadResource: false,
          key: each.url
        } 
      }
      
    } else {
      if(highlight) {
        return {
          header: {
            heading: highlight.title,
            profileImage: compressedImage(highlight.thumbnail),
          },
          url: each.url,
          type: each.type,
          preloadResource: false,
          key: each.url
        } 
      } else {
        return {
          header: {
            heading:"reclamado.por.la.naturaleza",
            profileImage: compressedImage(profile.profile_user[0].media_map_data["Profile Photo"].uri),
          },
          url: each.url,
          type: each.type,
          preloadResource: false,
          key: each.url
        }
      }
    }
  })

  return (
    <div>
      <Stories width={"100%"} height={"100%"} stories={highlights} onAllStoriesEnd={function(){window.history.back()}}/>
    </div>
  )
}

export default Highlight