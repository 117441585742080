import React, { useState, createContext,} from "react";
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Highlight from "./components/Highlight/Highlight";
import Reel from "./components/Reel/Reel";
// import PostTemplate from "./pages/PostTemplate";

// pages
import ProfileTemplate from './pages/ProfileTemplate';
import SinglePostTemplate from "./pages/SinglePostTemplate";

export const galleryContext = createContext();
export const isStyledContext = createContext();

function App() {
  const [activeGallery, setActiveGallery] = useState(0);
  const [isStyled, setIsStyled] = useState(true);

  return (
    <div className='flex justify-center'>
      <div className='App w-full max-w-[500px]'>
        <galleryContext.Provider value={[activeGallery, setActiveGallery]}>
          <isStyledContext.Provider value={[isStyled, setIsStyled]}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<ProfileTemplate />} />
                {/* <Route 
                  path="/post" 
                  element={
                      <PostTemplate />
                  }
                /> */}
                <Route path="/post/:id" element={<SinglePostTemplate />}/>
                <Route path="/highlight/:title" element={<Highlight />}/>
                <Route path="/reel/:id" element={<Reel />}/>
                <Route path="/:category" element={<ProfileTemplate />} />
              </Routes>
            </BrowserRouter>
          </isStyledContext.Provider>
        </galleryContext.Provider>
    </div>
    </div>
  );
}

export default App;
