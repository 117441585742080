import React from 'react'
import "./HighlightsSection.css"
import { disableRightClick, compressedImage } from '../../utils';
import { Link } from 'react-router-dom';
import _ from 'lodash';

function HighlightsSection(props) {
    const { items } = props;
  return (
    <div className='h-[88px] w-full flex justify-start gap-3 overflow-scroll highlights-slider pl-[21px]'>
        {items.map((item) =>
            <Link to={`/highlight/${_.toLower(item.title)}`} key={item.thumbnail}>
              <div className='flex flex-col items-center gap-1 min-w-[72px] cursor-pointer'>
                  <img src={compressedImage(item.thumbnail)} className='rounded-full object-cover h-16 w-16 p-1 border border-gray-700' alt="" onContextMenu={disableRightClick}/>
                  <div className='text-xs'>{item.title}</div>
              </div>
            </Link>
        )}
        
    </div>
  )
}

export default HighlightsSection