import React from 'react'
import { decodeFBString } from '../../utils';
import posts from "../../data/content/rpn_posts.json";
import { disableRightClick, compressedImage } from '../../utils';


function ProfileHeader(props) {
    const { profile } = props;
    const username = profile.profile_user[0].string_map_data.Username.value;
    const profileImg = profile.profile_user[0].media_map_data["Profile Photo"].uri;
    const profileName = profile.profile_user[0].string_map_data.Name.value;
    const profileBio = profile.profile_user[0].string_map_data.Bio.value;

  return (
    <div className='flex flex-col gap-4 mt-4'>
        <div className='h-10 flex justify-center items-center'>
        <a href="/">
            <span className='font-bold tracking-wider'>{username}</span>
        </a>
    </div>
    <div className='w-full flex flex-col gap-4'>
        <div className='flex flex-col gap-3'>
            <div className='max-w-[356px] flex justify-between'>
                <div style={{backgroundImage: `url(${compressedImage(profileImg)})`}} className="bg-img w-[78px] h-[78px] rounded-full border border-gray-700" onContextMenu={disableRightClick} />
                <div className='flex justify-evenly gap-6'>
                    <div className='flex flex-col justify-center items-center'>
                        <span className='font-bold'>{posts.length}</span>
                        <span>Posts</span>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <span className='font-bold'>88</span>
                        <span>Followers</span>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <span className='font-bold'>38</span>
                        <span>Following</span>
                    </div>
                </div>
            </div>
            <div className='text-left'>
                <div className='font-bold'>{profileName}</div>
                <pre className='whitespace-pre-wrap'>
                    {decodeFBString(profileBio)}
                    
                </pre>
            </div>
        </div>
        <div className='text-left'>
            <i>* Photos are only posted with <b>express permission</b> from the models. *</i>
        </div>
        <div className='flex gap-2'>
        <a href='https://cash.me/$Judepac' target='_blank' className='bg-[#0095f6] text-white rounded-lg flex-1 font-bold py-1' rel="noreferrer">Support</a>
        <a href='https://www.instagram.com/judeclarkeofficial/' target='_blank' className='bg-[#363636] text-white rounded-lg flex-1 font-bold py-1' rel="noreferrer">Message</a>
        </div>
        
    </div>
    </div>
  )
}

export default ProfileHeader