import React from 'react'
import Stories from "react-insta-stories"
import profile from "../../data/profiles/reclaimedByNature.json";
import { useParams } from 'react-router-dom';
import reels from "../../data/content/rpn_reels";
import _ from 'lodash';
import { disableRightClick, decodeFBString } from '../../utils';

function Reel() {

  const id = useParams().id;

  const video = reels.find(
    (reel) => {
      return reel.creation_timestamp.toString() === id.toString();
  }
  )
  
const constructReel = (vids) => {
  if(vids.length === 1) {
    vids.push(
      {
        url:
          "https://res.cloudinary.com/dlnhp6qmm/image/upload/v1690809538/media/rpn/reels/Rectangle_11_h3xoup.jpg",
        duration: 0
      })
  }
  const username = profile.profile_user[0].string_map_data.Username.value
  const profileImage = profile.profile_user[0].media_map_data['Profile Photo'].uri;
  const { model, tags } = video;
  
  const SeeMoreComponent = ({ vid, close }) => {
  
    return <div className="absolute bottom-0 bg-gradient-to-t h-1/3 w-full from-[#000000a0] from-85% py-10 px-3 overflow-scroll text-left" onClick={close}>
            <div className='flex gap-3 items-center pr-1 mb-3'>
                <div style={{backgroundImage: `url(${profileImage})`}} className="bg-img h-[42px] w-[42px] p-[5px] rounded-full" alt="" onContextMenu={disableRightClick}/>
                <div className='flex flex-col'>
                    <a href="/" className='font-bold'>{username}</a>
                </div>
            </div>
            <div>
            {decodeFBString(vid.seeMore)}<br/><br/>
            {model ? <span>Model: <a href={`https://instagram.com/${model}`} className='text-blue-200' target='_blank' rel="noreferrer">@{model}</a></span> : "Model: Asked not to tag"}<br/>
            {(tags && tags.length) && tags.map(tag => <div className='capitalize'>{tag.title}: <a href={tag.handle === "reclamado.por.la.naturaleza" ? "/" :`https://instagram.com/${tag.handle}`} className='lowercase text-blue-200' target={tag.handle === "reclamado.por.la.naturaleza" ? "" : '_blank'} rel="noreferrer">@{tag.handle}</a></div>)}
            </div>
            </div>;
  };

  return (
    vids.map((vid) => {
        return {
        url: vid.url,
        type: vid.type,
        duration: vid.duration,
        seeMore: ({ close }) => <SeeMoreComponent vid={vid} close={close} key={vid.url}/>,
        key: vid.url
        }
    })
    )
}

  const reel = constructReel(video.reel)


  return (
    <div onContextMenu={(e)=> e.preventDefault()} className='pb-5'>
      <Stories width={"100%"} height={"100%"} stories={reel} loop={true} progressContainerStyles={{display: "none"}} />
    </div>
  )
}

export default Reel