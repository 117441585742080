import React, { useContext, useEffect } from 'react';
import { galleryContext, isStyledContext } from '../../App';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import ImageGrid from '../ImageGrid/ImageGrid';
import ReelsGrid from '../ReelsGrid/ReelsGrid';
import profile from "../../data/profiles/reclaimedByNature.json";
import posts from "../../data/content/rpn_posts.json";
import reels from "../../data/content/rpn_reels.json";
import GalleryNav from '../GalleryNav/GalleryNav';
import HighlightsSection from '../HighlightsSection/HighlightsSection';
import highlights from "../../data/content/rpn_highlights";
import { useParams } from 'react-router-dom';

const items = highlights.highlights;
const styled = posts.filter((post) => (post.tags && post.tags.find((tag) => tag.title === "stylist")));
const nude = posts.filter((post) => !post.tags || (post.tags && !post.tags.find((tag) => tag.title === "stylist")));
let images;

const getImages = (posts, activeGallery) => {
  images = [];
  if(activeGallery && activeGallery === 3) {
    for(let i = 0; i < posts.length; i++) {
        const image = {url: posts[i].media[0].uri}
        image.isMultiple = posts[i].media.length > 1;
        if(posts[i].creation_timestamp){
          image.id = posts[i].creation_timestamp;
        } else {
          image.id = posts[i].media[0].creation_timestamp
        }
        images.push(image)
    }
  } else {
    for(let i = 0; i < posts.length; i++) {
      if(posts[i].pinned) {
        const image = {url: posts[i].media[0].uri}
        image.isMultiple = posts[i].media.length > 1;
        if(posts[i].creation_timestamp){
          image.id = posts[i].creation_timestamp;
        } else {
          image.id = posts[i].media[0].creation_timestamp
        }
        image.pinned = typeof posts[i].pinned === "number";
        images.splice(posts[i].pinned + 1, 0, image)
      } 
    }
    for(let i = 0; i < posts.length; i++) {
      if(!posts[i].pinned) {
        const image = {url: posts[i].media[0].uri}
        image.isMultiple = posts[i].media.length > 1;
        if(posts[i].creation_timestamp){
          image.id = posts[i].creation_timestamp;
        } else {
          image.id = posts[i].media[0].creation_timestamp
        }
        image.pinned = typeof posts[i].pinned === "number";
        images.push(image)
      } 
    }
  }
}
getImages(posts);


const thumbnails = reels.map((reel) => {
  return {
    id: reel.creation_timestamp,
    thumbnail: reel.thumbnail,
  };
}) 



function ProfilePage() {
  
const cat = useParams().category;
const [isStyled, setIsStyled] = useContext(isStyledContext);
const [activeGallery, setActiveGallery] = useContext(galleryContext);
useEffect(() => {
  if (cat === "styled") {
    setIsStyled(true);
    setActiveGallery(3);
  } else if (cat === "nude") {
    setIsStyled(false);
    setActiveGallery(3);
  }
}, [cat, setIsStyled, setActiveGallery]);


  const RenderGallery = () => {


    switch (activeGallery) {
      case 1:
        getImages(posts);
        return <ImageGrid images={images} />
      case 2:
        return <ReelsGrid images={thumbnails} key={thumbnails.id}/>
      case 3:
        const handleClick = (bool) => {
          setIsStyled(bool)
        }
        getImages(isStyled ? styled : nude, activeGallery);
        return <div className='flex flex-col gap-1'>
          <div className='flex w-full'>
            <span className={`w-1/2 h-14 flex items-center justify-center font-bold capitalize cursor-pointer ${isStyled && "border-b-2"}`} onClick={() => handleClick(true)}>styled</span>
            <span className={`w-1/2 h-14 flex items-center justify-center font-bold capitalize cursor-pointer ${!isStyled && "border-b-2"}`} onClick={() => handleClick(false)}>artistic nude</span>
          </div>
          <ImageGrid images={images} />
        </div>
      default:
        return <ImageGrid images={images} />
    }
  }
  return (
    <div className='w-full'>
      <div className='flex flex-col gap-4'>
      <div className='px-[21px] flex flex-col gap-4'>
         <ProfileHeader profile={profile} />
        </div>
        <HighlightsSection items={items} />
        <GalleryNav reels="true" tags="true" active={activeGallery} change={setActiveGallery}/>
      </div>
        
    {RenderGallery()}
    </div>
    
  )
}

export default ProfilePage