import React from 'react'
import Post from '../components/Post/Post'
import posts from "../data/content/rpn_posts.json";
import profile from "../data/profiles/reclaimedByNature.json";
import { useParams } from 'react-router-dom';
import PostHeader from '../components/PostHeader/PostHeader';
import { Helmet } from 'react-helmet';

const pinnedPosts = posts.filter(post => post.pinned).sort((a, b) => a.pinned - b.pinned);
const organizedPosts = [...pinnedPosts];
for(let i = 0; i < posts.length; i++) {
    if (!posts[i].pinned){
        organizedPosts.push(posts[i]);
    }
}

const postIds = organizedPosts.map((post) => {
    if(post.creation_timestamp) {
        return post.creation_timestamp
    } else if(post.media[0].creation_timestamp) {
        return post.media[0].creation_timestamp;
    } else {
        console.log("Missing timestamp: ", post)
    }
});

function SinglePostTemplate() {
    const id = useParams().id;
  
    const next = postIds[postIds.indexOf(+id) + 1];

    if(id) {
        const post = posts.find(
            (post) => {
                if(post.creation_timestamp) {
                    return post.creation_timestamp/1 === id/1
                } else {
                    return post.media[0].creation_timestamp/1 === id/1
                }
            }
        );
        if(post.title) {
        return <>
            <Helmet>
                <meta property="og:image" content={post.media[0].uri} />
                <meta property="og:description" content={post.title} />
            </Helmet>
            <PostHeader user={profile.profile_user[0].string_map_data.Username.value} />
            <Post id={post.creation_timestamp} media={post.media} description={post.title} location={post.location} model={post.model} tags={post.tags} user={profile} nextPost={next} key={post.creation_timestamp}/>
         </>
        } else {
        return <>
            <Helmet>
                <meta property="og:image" content={post.media[0].uri} />
                <meta property="og:description" content={post.media[0].title} />
            </Helmet>
            <PostHeader user={profile.profile_user[0].string_map_data.Username.value} />
            <Post id={post.media[0].creation_timestamp} media={post.media} description={post.media[0].title} location={post.media[0].location} model={post.media[0].model} tags={post.media[0].tags} user={profile} nextPost={next} key={post.creation_timestamp}/>
        </>
        }
    }
}

export default SinglePostTemplate