import React, {useEffect} from 'react'
import Carousel from '../Carousel/Carousel'
import "./Post.css"
import 'swiper/css';
import like from "../../icons/like.svg";
import comment from "../../icons/comment.svg";
import send from "../../icons/send.svg";
import save from "../../icons/save.svg";
import dots from "../../icons/dots.svg"
import { useState } from 'react';
import { decodeFBString } from '../../utils';
import { disableRightClick, compressedImage } from '../../utils';



function Post(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    const [liked, setLiked] = useState(false);
    const toggleLike = () => {handleLikeAnimation(); setLiked(!liked)};
    const { id, media, description, location, user, model, tags, nextPost, ...rest } = props
    const images = media.map(item => item.uri);
    const username = user.profile_user[0].string_map_data.Username.value;
    const profileImg = user.profile_user[0].media_map_data["Profile Photo"].uri;

    const handleLikeAnimation = (e) => {
        if(liked === true) {
          return
        } else {
          setLiked(true);
          setLikeHeartOpacity();
        }
      }
    
    
      function setLikeHeartOpacity() {
        const likeHeartDivs = document.querySelectorAll(".like-heart");
      
        if (likeHeartDivs) {
          likeHeartDivs.forEach((div) => {
            div.classList.add("animating");
          })
      
          setTimeout(() => {
            likeHeartDivs.forEach((div) => {
                div.classList.remove("animating");
              })
          }, 1100);
        }
      }

      function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
          .then(() => {
            console.log('Text copied to clipboard');
            alert("Copied to Clipboard!")
          })
          .catch((error) => {
            console.error('Error copying text to clipboard:', error);
          });
      }
      const handleClick = () => {
        if(navigator.share) {
          navigator.share({
            url: `https://reclamadoporlanaturaleza.com/post/${id}`,
          })
        } else {
          copyToClipboard(`https://reclamadoporlanaturaleza.com/post/${id}`)
        }
      }
      props.nextPost && console.log(props.nextPost);

  return (
    <div id={id} className="post flex flex-col gap-1 mb-8" style={{minHeight: "calc(100vh - 48px)"}} {...rest} >
        <div className="pt-4 pb-2 pl-3 pr-2 flex justify-between items-center w-full mt-11">
            <div className='flex gap-3 items-center pr-1'>
                <div style={{backgroundImage: `url(${compressedImage(profileImg)})`}} className="bg-img h-[42px] w-[42px] p-[5px] rounded-full" alt="" onContextMenu={disableRightClick}/>
                <div className='flex flex-col'>
                    <a href="/" className='font-bold'>{username}</a>
                    {location && 
                    <span className='text-xs p-[2px] text-left'>{location}</span>
                    }
                </div>
            </div>
            <img src={dots} className='w-10 p-[11px] contain' alt="" />
        </div>
        <div>
            <div className='flex flex-col'>
            <Carousel animation={handleLikeAnimation}>
                {images}
            </Carousel>
            <div className='mt-1 pt-[6px] pb-2 px-4 flex justify-between items-center w-full'>
                <div className='flex'>
                    <img src={like} className={`like-heart h-10 w-10 p-2 contain hover:brightness-[2000%] cursor-pointer transition-all duration-100 ${liked ? "brightness-[2000%]" : "brightness-100"}`} onClick={toggleLike} alt="" />
                    <img src={comment} className='h-10 w-10 p-2 contain'  alt=""/>
                    <button onClick={handleClick}><img src={send} className='h-10 w-10 p-2 contain'  alt=""/></button>
                </div>
                <img src={save} className='h-10 w-10 p-2 contain' alt=""/>
            </div>
            <pre className='whitespace-pre-wrap px-6'>
            <a href="/" className='font-bold'>{username} </a>{decodeFBString(description)}<br/><br/>
            {model ? <span>Model: <a href={`https://instagram.com/${model}`} className='text-blue-200' target='_blank' rel="noreferrer">@{model}</a></span> : "Model: Asked not to tag"}<br/>
            
            {(tags && tags.length) && tags.map(tag => <div className='capitalize' key={`tag-${id}`}>{tag.title}: <a href={tag.handle === "reclamado.por.la.naturaleza" ? "/" :`https://instagram.com/${tag.handle}`} className='lowercase text-blue-200' target={tag.handle === "reclamado.por.la.naturaleza" ? "" : '_blank'} rel="noreferrer">@{tag.handle}</a></div>)}
            {/* {tags && tags.forEach( (tag) => {
              <span>{tag.title}: <a href={`https://instagram.com/${tag.handle}`} target='_blank' rel="noreferrer">@{tag.handle}</a></span>
            }
            )} */}
            <br/><small>** Posted with permision **</small>
            </pre>
            {props.nextPost && 
              <a href={`/post/${props.nextPost}`} className='next-post mt-12 p-4 font-bold bg-zinc-900 transition-opacity duration-300'>
              Next Post
              </a>
            }
            
            </div>
            
        </div>
        
    </div>
  )
}

export default Post
