import React from 'react'
import "./GridSquare.css"
import stack from "../../../src/icons/stack.svg"
import pin from "../../../src/icons/pin.png"
import { HashLink as Link} from "react-router-hash-link";
import Image from '../Image/Image';
import { disableRightClick, compressedImage } from "../../utils";



function GridSquare(props) {
  const { imgSrc, isMultiple, id, isPinned, reels } = props;

  return (
    <Link className='grid-item relative' to={(reels === true) ? `/reel/${id}` : `/post/${id}`}>
    <div className={`image w-full ${(reels === true) ? "rectangle" : "square"}`}>
      {(isMultiple && !isPinned) && <img src={stack} className="w-[18px] absolute m-[10px] right-0" alt="" />}
      { isPinned && <img src={pin} className="pin-icon w-[22px] absolute m-[10px] right-0" alt="" />}
      <div style={{backgroundImage: `url(${compressedImage(imgSrc)})`}} className={`bg-img w-full ${(reels === true) ? "rectangle" : "square"}`} onContextMenu={disableRightClick}/>
      {/* <Image className={`image w-full ${(reels === true) ? "rectangle" : "square"}`} src={compressedImage(imgSrc)} /> */}
    </div>
    </Link>
  )
}

export default GridSquare