import React from "react"
import chevron from "../../icons/chevron.svg"
import { useNavigate } from "react-router-dom"


function PostHeader(props) {
    const { user } = props
    const navigate = useNavigate()

    const handleBackClick = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/');
        }
    }

  return (
    <div className="fixed h-12 border-b border-b-[#222222] z-50 w-full max-w-[500px]">
        <div className="pr-3 pt-1 h-full flex items-center bg-[#080808]">
            <button onClick={handleBackClick} className="p-3">
                <img src={chevron} alt="" />
            </button>
            <a href="/" className="mx-auto flex flex-col">
                <span className="uppercase font-bold text-xxs opacity-60 ">{user}</span>
                <span className="font-bold text-sm">Posts</span>
            </a>
        </div>
    </div>
  )
}

export default PostHeader;