import React from 'react'
import gridIcon from  "../../icons/grid.svg"
import reelIcon from  "../../icons/reel.svg"
import tagIcon from  "../../icons/tag.svg"
import "./GalleryNav.css";
import { useState, useEffect, useMemo} from 'react';

function GalleryNav(props) {
    const { reels, tags, active, change} = props;

    const galleries = useMemo(() => ["imageGallery", "reelsGallery", "tagsGallery"], []);
    const [currGallery, setCurrGallery] = useState(galleries[0]);
    
    useEffect(() => {
      if(active) {
        setCurrGallery(galleries[active - 1])
      }
    }, [active, galleries]);

    const selectImage = () => change(1)
    const selectReels = () => change(2)
    const selectTags = () => change(3)

  return (
    <div className='flex w-full h-13 pt-3 mb-[2px] sm:mb-1 justify-between items-center'>
        <img src={gridIcon} onClick={selectImage} className={`${(reels && !tags || !reels && tags) ? "w-full" : "galleryIcon" } h-10 border-b-white cursor-pointer ${currGallery === "imageGallery" ? "pb-[14px] border-b-[1px] sm:border-b-2" : "pb-4"}`} alt="" />
        {reels && <img src={reelIcon} onClick={selectReels} className={`${(reels && !tags || !reels && tags) ? "w-full" : "galleryIcon" } h-10 border-b-white cursor-pointer ${currGallery === "reelsGallery" ? "pb-[14px] border-b-[1px] sm:border-b-2" : "pb-4"}`} alt="" />}
        {tags && <img src={tagIcon} onClick={selectTags} className={`${(reels && !tags || !reels && tags) ? "w-full" : "galleryIcon" } h-10 border-b-white cursor-pointer ${currGallery === "tagsGallery" ? "pb-[14px] border-b-[1px] sm:border-b-2" : "pb-4"}`} alt="" />}
    </div>
  )
}

export default GalleryNav